* {
	box-sizing: border-box;
	margin: 0;
	font-family: "Nunito", sans-serif;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
	padding: 20px 10px;
	width: 100%;
	max-width: 1550px;
	height: 100vh;
	margin: 0 auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.wykres { width: 100%; height: 70px; position: relative; }
.wykres .tytul { position: absolute;
    left: 15px;
    top: 0;
    bottom: 25px;
	font-weight: bold; color: #1c1c1c; text-shadow: 1px 1px 3px #aaa;
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center; }
.wyjsciowa { height: 100px; }
.przyciski button, .klikalne button { padding: 5px; border: none; cursor: pointer; width: 30px; font-weight: bold; color: #fff; transition: background .4s ease; }
.przyciski button { width: unset; text-shadow: 1px 1px 3px #555;}
.klikalne p { padding: 5px; color: #fff; font-weight: bold; transition: background .4s ease; }